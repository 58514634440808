// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-books-index-js": () => import("./../../../src/pages/books/index.js" /* webpackChunkName: "component---src-pages-books-index-js" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-book-page-js": () => import("./../../../src/templates/book-page.js" /* webpackChunkName: "component---src-templates-book-page-js" */),
  "component---src-templates-events-page-js": () => import("./../../../src/templates/events-page.js" /* webpackChunkName: "component---src-templates-events-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-mabel-page-js": () => import("./../../../src/templates/mabel-page.js" /* webpackChunkName: "component---src-templates-mabel-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-years-page-js": () => import("./../../../src/templates/years-page.js" /* webpackChunkName: "component---src-templates-years-page-js" */)
}

